<template>
  <main role="main">
    <meta-title :title="metaTitle"></meta-title>
    <section class="section section-captive" v-if="!showPreloader">
      <div class="container">
        <div class="columns">
          <div class="left-col">
            <img src="@/assets/i/ife-one-logo.svg" alt="" />

            <p v-html="$t('captive.hello_title')"></p>
          </div>
          <div class="right-col text-col" v-if="$route.query.policy">
            <h4>
              <a
                class="back"
                href=""
                @click.prevent="$router.push('/captive')"
              ></a>
              {{ $t("captive.policy_title") }}
            </h4>
            <div
              class="scroll-container"
              v-html="$t('captive.policy_html')"
            ></div>
          </div>

          <div class="right-col" v-else>
            <p>{{ $t("captive.choice_language") }}</p>
            <ul class="chooser">
              <li
                :class="{ selected: $i18n.locale === 'ru' }"
                @click.prevent="setLocale('ru')"
              >
                <a href="#" @click.prevent="setLocale('ru')"
                  ><img src="@/assets/i/flag-ru.png" alt="" />Русский</a
                >
              </li>
              <li
                :class="{ selected: $i18n.locale === 'en' }"
                @click.prevent="setLocale('en')"
              >
                <a href="#" @click.prevent="setLocale('en')"
                  ><img src="@/assets/i/flag-en.png" alt="" />English</a
                >
              </li>
            </ul>

            <form method="post" enctype="multipart/form-data">
              <div class="buttons">
                <input
                  type="button"
                  class="next"
                  name="next"
                  :value="$t('captive.next')"
                  @click.prevent="next()"
                />
              </div>

              <p class="legal" v-if="isFlight">
                {{ $t("captive.policy_text") }}
                <router-link to="/captive?policy=1"
                  >{{ $t("captive.policy_link") }} </router-link
                >.
              </p>
            </form>
          </div>
        </div>
      </div>
    </section>
    <CheckDrmPlayer
      @endplaylist="endPlaylistDRM"
      v-if="data && settingsIndexLoading === false"
      class="hidden"
      ref="drmTest"
      :data="data"
      :test="true"
      :advertise-data="{}"
    ></CheckDrmPlayer>

    <Player
      v-show="showPreloader"
      v-if="settingsIndexLoading === false && advertiseData"
      @endplaylist="endPlaylist"
      ref="pl"
      :data="{}"
      :test="true"
      :advertise-data="advertiseData"
    ></Player>
  </main>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Player from "@/components/Player";
import isFlight from "@/mixins/isFlight";
import CheckDrmPlayer from "@/components/CheckDrmPlayer";
import events from "@/mixins/events";

export default {
  name: "Captive",
  title() {
    return this.$t("pages.main");
  },
  mixins: [isFlight, events],
  components: { CheckDrmPlayer, Player },
  computed: {
    ...mapState("user", {
      authenticate: state => state.authenticate
    }),
    ...mapState("settings", {
      settingsIndexLoading: state => state.indexLoading,
      indexCaptive: state => state.index
    })
  },
  watch: {
    showPreloader(value) {
      if (value) {
        document.documentElement.classList.add("only-video");
      } else {
        document.documentElement.classList.remove("only-video");
      }
    },
    settingsIndexLoading: {
      handler() {
        if (this.settingsIndexLoading === false) {
          if (
            this.$browserDetect.isSafari ||
            this.$browserDetect.isIOS ||
            this.$browserDetect.isChromeIOS
          ) {
            this.data = false;
          } else {
            this.data = {
              isDrm: true,
              cover: "/favicon.ico",
              contentItems: [
                {
                  stream: "/drm-test/Manifest.mpd",
                  streamHls: "/drm-test/Manifest.mpd"
                }
              ]
            };
          }
          try {
            this.advertiseData = {
              adRolls: {
                preroll: {
                  adVideos: { ...this.indexCaptive.captivePage.ads, id: -1 }
                }
              }
            };
          } catch (e) {
            this.advertiseData = false;
            this.showPreloader = false;
            this.setSettingsEntity(["preload", true]);
          }
        }
      },
      immediate: true
    }
  },
  data: () => {
    return {
      user: {
        firstName: "",
        lastName: "",
        seat: ""
      },
      data: false,
      errors: false,
      advertiseData: false,
      showPreloader: false
    };
  },
  beforeDestroy() {
    document.documentElement.classList.remove("only-video");
  },
  methods: {
    ...mapActions("user", {
      loginAction: "loginUser"
    }),
    ...mapMutations("settings", {
      setSettingsEntity: "SET_ENTITY"
    }),
    ...mapMutations("user", {
      setUserEntity: "SET_ENTITY"
    }),
    next() {
      this.setUserEntity(["skipCaptive", true]);
      this.showPreloader = true;
      try {
        this.$refs.drmTest.play();
      } catch (e) {
        this._drmActivationEvent("Error");
      }
      try {
        this.$refs.pl.play();
      } catch (e) {
        this.setSettingsEntity(["preload", true]);
        this.$router.push("/");
      }
    },
    async submit() {
      await this.loginAction(this.user);

      this.errors = !this.authenticate;
      if (this.authenticate) {
        this.showPreloader = true;
        await this._loginEvent();
        try {
          this.$refs.pl.play();
        } catch (e) {
          console.log(e);
          this.setSettingsEntity(["preload", true]);
          await this.$router.push("/");
        }

        try {
          this.$refs.drmTest.play();
        } catch (e) {
          console.log(e);
          await this._drmActivationEvent("Error");
        }
      }
    },
    ...mapActions({
      setLocale: "SET_LOCALE"
    }),
    endPlaylistDRM() {
      this.$refs.drmTest.unload();
      this.data = false;
    },
    endPlaylist() {
      this.setSettingsEntity(["preload", true]);
      this.$router.push("/");
    }
  }
};
</script>

<style scoped></style>
